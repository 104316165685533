import {useTranslation} from "react-i18next";
import format from "../utils/dateFormat";
import "./order-list.css"

const YesNoIcon = ({value}) => {
  const {t, i18n} = useTranslation();
  return value ?
    <span className="icon yes">{t('yes')}</span> :
    <span className="icon no">{t('no')}</span>
}

const OrderAsListItem = ({order, handleOrderClick}) => {
  const {t, _} = useTranslation();
  const created = format(order.createdDate);
  const untitled = t('orders.untitled');

  let cells = [
    <td className="id">{order.id}</td>,
    <td className="reference">{order.reference}</td>,
    <td className="title">{order.title || untitled}</td>,
    <td className="date">{created}</td>,
    <td className="summary">{order.summary}</td>,
    <td className="totalLoad">{order.totalLoad} {order.currency}</td>,
    <td className="status">
      {t(`orders.orderStatus-${order.status}`)}
      <label>
        {t(`orders.approved-${order.approved}`)}
      </label>
    </td>,
    <td className="loadOnFulfillment"><YesNoIcon value={order.loadOnFulfillment}/></td>,
    <td className="activateOnFulfillment"><YesNoIcon value={order.activateOnFulfillment}/></td>,
  ]
  return <tr data-id={order.id} onClick={handleOrderClick} key={order.id}>{cells}</tr>
}

const OrderList = ({orders, searchPhrase, handleOrderClick}) => {
  const {t, _} = useTranslation();
  const searchPhraseFilter = (order) => {
    const searchLowercase = searchPhrase ? searchPhrase.toLocaleLowerCase() : "";
    return !searchPhrase ||
      order.reference.toLocaleLowerCase().indexOf(searchLowercase) >= 0 ||
      order.title.toLocaleLowerCase().indexOf(searchLowercase) >= 0
  }
  const ordersFiltered = orders?.filter(searchPhraseFilter);

  let contents = (ordersFiltered && ordersFiltered.length) ?
    ordersFiltered.map((o) => <OrderAsListItem order={o} key={o.id} handleOrderClick={handleOrderClick}/>) :
    <tr><td colSpan={6}>{t('orders.noOrdersFound')}</td></tr>

  return <table className="orderList">
    <thead>
    <tr>
      <th className="id">{t('orders.id')}</th>
      <th className="reference">{t('orders.reference')}</th>
      <th className="title">{t('orders.title')}</th>
      <th className="date">{t('orders.created')}</th>
      <th className="summary">{t('orders.summary')}</th>
      <th className="totalLoad">{t('orders.totalLoad')}</th>
      <th className="status">{t('orders.status')}</th>
      <th className="loadOnFulfillment">{t('orders.loadImmediately')}</th>
      <th className="activateOnFulfillment">{t('orders.activateImmediately')}</th>
    </tr>
    </thead>
    <tbody>{contents}</tbody>
  </table>
}

export default OrderList;