import HTTP from "./http";
import GiftCardOrder, {GiftCard, GiftCardsOrder} from "../models/orders"

const API_ADMIN = (baseUrl = '') => {

    baseUrl = baseUrl || (window.GIFTSTARR_CFG && window.GIFTSTARR_CFG.API_URL) || '/api'
    const http = HTTP(baseUrl);

    const getDefaults = async () => {
        return http.get('/orders/getDefaults')
    }
    const getOrders = async () => {
        return http.get('/orders/getOrders')
    }
    const getOrder = async (id) => {
        return http.get(`/orders/getOrder/${id}`)
    }
    const placeOrder = async (order: GiftCardOrder) => {
      return http.post('/orders/placeOrder', order);
    }
    const updateOrder = async (order: GiftCardOrder) => {
      return http.put('/orders/updateOrder', order);
    }
    const commitOrder = async (orderId) => {
      return http.put(`/orders/commitOrder?order_id=${orderId}`);
    }
    const cancelOrder = async (orderId) => {
      return http.put(`/orders/cancelOrder?order_id=${orderId}`);
    }
    const activateAllCards = async (orderId) => {
      return http.put(`/orders/activateAllCards?order_id=${orderId}`);
    }
    const loadAllCards = async (orderId) => {
      return http.put(`/orders/loadCards?order_id=${orderId}`);
    }

    return {
      getDefaults:getDefaults,
      getOrders:getOrders,
      getOrder:getOrder,
      placeOrder:placeOrder,
      commitOrder:commitOrder,
      cancelOrder:cancelOrder,
      updateOrder:updateOrder,
      activateAllCards:activateAllCards,
      loadAllCards:loadAllCards
    }
}

export default API_ADMIN;