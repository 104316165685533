import React from 'react';
import GiftCardProvider from "./comps/store";
import ErrorBoundary from "./comps/error-boundary";
import MessageEditor from "./comps/message-editor";
import MessagePlayer from "./comps/message-player";
import './App.css'
import Router, {Route, RouterProvider} from "./comps/router";
import BalanceCheck from "./comps/screens/balance-check";
import GiftcardHome from "./comps/screens/giftcard-home";
import Enquiry from "./comps/screens/enquiry";
import Admin from "./comps/screens/admin";

const Header = ({brand}) => {
    return <header>
        <h2>{brand}</h2>
    </header>
}

const Footer = () => {
    return <footer>&copy; Agantis 2024</footer>
}

function App() {

    const brandName = 'giftstarr'

    return (
        <ErrorBoundary>
                <GiftCardProvider>
                    <div className={"giftcards " + brandName}>
                        <RouterProvider>
                            <Router>
                                <Route path="/admin" ><Admin/></Route>
                                <Route path="/enquiry" ><Enquiry/></Route>
                                <Route path="/:token"><GiftcardHome/></Route>
                                <Route path="/editor"><MessageEditor/></Route>
                                <Route path="/view" ><MessagePlayer/></Route>
                                <Route path="/balance/:token" ><BalanceCheck/></Route>
                                {/*<Route path="/:token" ><BalanceCheck/></Route>*/}

                            </Router>
                        </RouterProvider>
                        <Footer/>
                    </div>
                </GiftCardProvider>
        </ErrorBoundary>
    );
}

export default App;