const MAX_AMOUNT = 9999;
const MAX_CARDS = 1000;
const PASTE_CARD_DATA_REGEX = /^\s*(http\S+\/)?(\d{9,10})?(\W*(\d{1,4}([,.]\d{1,2})?))?([^\r\n\w]*([^\r\n\t]+?))?$/gm

const cleanToken = (inputVal) => {
  const inputStr = String(inputVal);
  const m = inputStr.match(/\d{9}/)
  if(m){ return m[0]} else return inputVal
}

const cleanAmount = (inputVal) => {
  const inputStr = String(inputVal).replaceAll(",",".");
  let numVal = parseFloat(inputStr).toFixed(2);
  if(numVal > MAX_AMOUNT) numVal = MAX_AMOUNT;
  if(numVal < 0) numVal = 0;
  return numVal;
}

const cleanCardCount = (inputVal) => {
  const inputStr = String(inputVal)
  let numVal = parseInt(inputStr)
  if(numVal > MAX_CARDS) numVal = MAX_CARDS;
  if(numVal < 1) numVal = 1;
  return numVal;
}

const sanitize = (field, inputVal) => {
  switch(field){
    case "token": return cleanToken(inputVal);
    case "loadAmount": return cleanAmount(inputVal);
    case "cardCount": return cleanCardCount(inputVal);
    default: return inputVal
  }
}

const getTargetIndexInBatch = (target) => {
  const closestBatch = target.closest(".batch")
  const closestCard = target.closest(".card")
  return {
    batchIndex: parseInt(closestBatch.dataset.index),
    selectedCardIndex: parseInt(closestCard.dataset.index)
  }
}

const parsePasteClipboard = (e, order) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData('text/plain');
    const regex = PASTE_CARD_DATA_REGEX;
    const matches = [...pasted.matchAll(regex)];
    let _, token, amount, name;
    const items = matches.map((m, i) => {
      console.log("parsing match " + i);
      [_, _, token, _, amount, _, _, name] = [...m];
      console.log(m);
      return {token:token, loadAmount:amount, cardholderName:name};
    });
    const indices = getTargetIndexInBatch(e.target);
    let batch = order.cardBatches[indices.batchIndex];
    let cards = batch.cards;

    items.forEach((item, i) => {
      let card = cards[i + indices.selectedCardIndex];
      if(card){
        card.token = item.token || card.token;
        card.loadAmount = (batch.useIndividualLoadAmounts && item.loadAmount) || batch.loadAmount;
        card.cardholderName = (batch.useIndividualCardholders && item.cardholderName) || card.cardholderName;
        console.log(batch.useIndividualCardholders, item)
      }
    });


  }

export default sanitize;
export {parsePasteClipboard}