const HTTP = (url) => {

  let baseUrl = url || '/api';
  const DEFAULT_HEADERS = {
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow",
    referrerPolicy: "no-referrer"
  }

  const doFetch = async (url, init=null) => {
      let result;
      let response;
      try {
          response = await fetch(baseUrl + url, init);
          if(response.ok) {
              try {
                  result = await response.json()
              } catch (e){
                  result = {error: e.toString()}
              }
          } else if(response.status >= 400 && response.status <= 499) {
              result = {error: (await response.json()).detail}
          } else {
            result = {
              error: response.status,
              detail: response.statusText
            }
          }
      } catch (e) {
          console.log(e)
          result = {error: e.toString()};
      }
      return result
  }

  const get = async (url) => {
      return doFetch(url);
  }

  const del = async (url) => {
      const init = {
          method: "DELETE",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrerPolicy: "no-referrer"
        };
      return doFetch(url, init);
  }

  const post = async (url, data = {}) => {
      const init = {
          ...DEFAULT_HEADERS,
          method: "POST",
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        };
      return doFetch(url, init);
  }

  const put = async (url, data = {}) => {
      const init = {
          ...DEFAULT_HEADERS,
          method: "PUT",
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        };
      return doFetch(url, init);
  }



  return {
    post:post,
    put:put,
    get:get,
    del:del
  }
}

export default HTTP;