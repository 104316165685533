
const CRUNCH_TOKEN_RE = /^\d{9}$/
const MAX_LOAD_AMOUNT = 9999;

class GiftCard {
  #lastError = "";
  #loadAmount = 0;
  #token = "";

  static fromObject = (c) => {
    const card = Object.assign(new GiftCard(), c);
    return card
  }

  constructor(token, loadAmount, cardholderName="") {
    this.id = 0;
    this.index = 0;
    this.#token = this.castToString(token);
    this.loadAmount = loadAmount
    this.cardholderName = cardholderName;
    this.currency = "";
    Object.defineProperty(this, 'token', {
      enumerable: true,
      set (t){
        let tStr = this.castToString(t);
        if(tStr) this.#token = tStr;
      },
      get () {
        return this.#token;
      }
    });
    Object.defineProperty(this, 'loadAmount', {
      enumerable: true,
      get () {
        return this.#loadAmount;
      },
      set (amount) {
        this.#loadAmount = parseFloat(String(amount).replace(",","."));
      }
    });

  }

  castToString(t) {
    return (t?.valueOf() === undefined) ? "" : String(t);
  }

  get lastError() {
    return this.#lastError;
  }

  get isEmpty() {
    return !this.token && !this.loadAmount;
  }

  get hasValidToken() {
    return this.token && this.token.match(CRUNCH_TOKEN_RE) ;
  }

  get hasAmount() {
    return !isNaN(this.loadAmount) && this.loadAmount > 0
  }

  get hasCardholder() {
    return this.cardholderName.trim().length > 2;
  }

  get isValid() {
    if(!this.token){
      this.#lastError = "missing token";
      return false;
    }
    if(!this.hasValidToken){
      this.#lastError = `invalid token, should be 9-digits`;
      return false;
    }
    if(isNaN(this.loadAmount)){
      this.#lastError = `load amount is missing`;
      return false;
    }
    if(this.loadAmount <= 0){
      this.#lastError = `load amount has to be more than zero`;
      return false;
    }
    if(this.loadAmount > MAX_LOAD_AMOUNT){
      this.#lastError = `load amount is too big (max ${MAX_LOAD_AMOUNT})`;
      return false;
    }
    this.#lastError = "";
    return true;
  }

}

class GiftCardBatch {

  static fromObject = (b) => {
    const batch = Object.assign(new GiftCardBatch(), b);
    batch.cards = b.cards
      .map( c => GiftCard.fromObject(c))
      .map( (c, i) => {c.index = i; return c});
    return batch
  }

  constructor(loadAmount, currency, orderId = null, index = null) {
    this.id = 0
    this.orderId = parseInt(orderId) || 0
    this.loadAmount = loadAmount
    this.index = index
    this.cards = []
    this.currency = currency
    this.useIndividualCardholders = false;
    this.isVisible = true;
  }
  updateIndices() {
    this.cards.forEach((c, i) => c.index = i);
    return this;
  }
  get useIndividualLoadAmounts() {
    return this.loadAmount === 0;
  }
  get cardCount() {
    return this.cards.length
  }
  get summary() {
    return this.cardCount + " x " + (this.useIndividualLoadAmounts ? "var" : this.loadAmount);
  }
  get totalLoad() {
      if(this.useIndividualLoadAmounts) {
        return this.cards.reduce((sum, card) => sum + card.loadAmount, 0);
      } else {
        return this.cardCount * this.loadAmount;
      }
  }
  set cardCount(newCount) {
    if (newCount > this.cards.length) {
      while (this.cards.length < newCount) {
        let card = new GiftCard()
        card.batchId = this.id
        card.currency = this.currency
        card.loadAmount = this.loadAmount
        this.cards.push(card)
      }
    }
    if (newCount < this.cards.length) {
      while (this.cards.length < newCount) {
        let emptyTokenIndex = this.cards.findIndex((c) => c.isEmpty())
        if (emptyTokenIndex !== -1) this.cards.splice(emptyTokenIndex, 1)
        else
          throw new Error("Cannot trim card count because of existing tokens")
      }
    }
    this.updateIndices();
  }
  get hasAllCardsAssigned() {
    return this.cards.every( c => c.hasValidToken )
  }
  get hasAllCardsValid() {
    return this.cards.every( c => c.isValid )
  }


  addCards(cards) {
    let nonEmpty = this.cards.filter((t) => !t.isEmpty)
    let max = this.cardCount - nonEmpty.length
    if (Array.isArray(cards)) {
      if (max < cards.length)
        throw new Error(
          `Trying to add ${cards.length} tokens while only ${max} can be added to the batch`,
        )
      nonEmpty.push(...cards)
    } else {
      if (max < 1) throw new Error("No more tokens can be added to the batch")
      nonEmpty.push(cards)
    }
    this.cards = nonEmpty
    this.updateIndices();
    return this
  }
}

class GiftCardsOrder {
  static STATUS_ENUM = {
    OPEN:"OPEN",
    COMMITED:"COMMITED",
    CARDS_ASSIGNED:"CARDS_ASSIGNED",
    CARDS_LOADED:"CARDS_LOADED",
    CARDS_ACTIVE:"CARDS_ACTIVE",
    CANCELLED:"CANCELLED"
  };
  static ACTION_ENUM = {
    ASSIGN_CARDS: "ASSIGN_CARDS",
    COMMIT_ORDER: "COMMIT_ORDER",
    ACTIVATE_CARDS: "ACTIVATE_CARDS",
    LOAD_CARDS: "LOAD_CARDS",
    CANCEL_ORDER: "CANCEL_ORDER",
    SAVE_ORDER: "SAVE_ORDER"
  };

  static fromObject = (o) => {
    const order = Object.assign(new GiftCardsOrder(), o);
    order.cardBatches = o.cardBatches
      .map( b => GiftCardBatch.fromObject(b))
      .map( (b, i) => {
        b.index = i;
        b.cardCount = b.cards.length;
        return b
      })
    return order
  }

  constructor(
    clientId,
    title,
    currency,
    activateOnFulfillment = true,
    loadOnFulfillment = true,
    createdDate = new Date(),
    id = null,
    status = GiftCardsOrder.STATUS_ENUM.OPEN,
    cardBatches = [],
    notifyEmails = ""
  ) {
    this.id = id || 0
    this.clientId = clientId
    this.reference = ""
    this.title = title
    this.currency = currency
    this.activateOnFulfillment = activateOnFulfillment
    this.loadOnFulfillment = loadOnFulfillment
    this.createdDate = (createdDate instanceof Date) ? createdDate : new Date(createdDate)
    this.notifyEmails = notifyEmails

    if (!(status in GiftCardsOrder.STATUS_ENUM))
      throw new Error(
        "Order status not recognised:" +
          status +
          "; allowed: " +
          Object.keys(GiftCardsOrder.STATUS_ENUM).join(),
      )
    this.status = status
    this.cardBatches = []
  }
  addBatch(batch) {
    batch.orderId = this.id
    batch.index = this.cardBatches.length
    batch.currency = this.currency
    this.cardBatches.push(batch)
    return this
  }

  get summary() {
    return this.cardBatches
      .map(b => b.summary)
      .join(", ");
  }
  get totalLoad() {
    return this.cardBatches.reduce(
      (sum, b) => sum + b.totalLoad,
      0,
    );
  }
  get totalCardCount() {
    return this.cardBatches.reduce(
      (sum, b) => sum + b.cardCount,
      0,
    );
  }
  get isValid() {
    return this.totalLoad > 0 && this.totalCardCount > 0
  }
  get canSave() {
    const ENUM = GiftCardsOrder.STATUS_ENUM;
    return  this.isValid &&
      [ENUM.OPEN, ENUM.CARDS_ASSIGNED].includes(this.status)
  }
  get canCancel() {
    const ENUM = GiftCardsOrder.STATUS_ENUM;
    return [ENUM.OPEN, ENUM.CARDS_ASSIGNED].includes(this.status);
  }
  get canEditCards() {
    return  this.canSave
  }
  get canEditBatches() {
    return !!this.id;
  }
  get hasAllCardsAssigned() {
    return this.cardBatches.every( b => b.hasAllCardsAssigned )
  }
  get hasAllCardsValid() {
    return this.cardBatches.every( b => b.hasAllCardsValid )
  }



}

export {GiftCard, GiftCardBatch, GiftCardsOrder}