
import API_ADMIN from "../api-admin";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Order, {ErrorDialog} from "../orders/order-components";
import {LoadingSpinner} from "./giftcard-home-comps";
import "./admin.css"
import OrderList from "../orders/orders-list";
import {GiftCardsOrder} from "../../models/orders";

const Admin = () => {
  const DEFAULT_MODE = "default";
  const api = API_ADMIN();
  const {t, i18n} = useTranslation();
  const [DEFAULTS, SET_DEFAULTS] = useState({
    clientId: 0,
    currency: 'GBP'
  });
  const [isLoading:boolean, setIsLoading] = useState();
  const [editorMode, setEditorMode] = useState(DEFAULT_MODE);
  const [orders, setOrders] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [searchPhrase, setSearchPhrase] = useState("");
  const [error, setError] = useState(false);

  const showError = (msg, techInfo) => {
    setError({msg:msg, techInfo:techInfo})
  }
  const clearError = () => {
    setError(false)
  }

  const handleSearchPhraseChange = (e) => {
    setSearchPhrase(e.target.value);
  }
  const handleLoadOrders = (e) => {
    if(!isLoading) loadOrders();
  }

  const handleOrderClick = (e) => {
    const id = parseInt(e.currentTarget.dataset.id);
    const o = orders.find( o => {return o.id === id});
    setCurrentOrder(o);
    setEditorMode(DEFAULT_MODE);
  }

  const handleAddOrder = (e) => {
    setEditorMode(DEFAULT_MODE);
    const newOrder = new GiftCardsOrder(
      DEFAULTS.clientId,
      t("orders.newOrder"),
      DEFAULTS.currency,
      false,
      true
    );
    setCurrentOrder(newOrder);
  }

  const loadDefaults = async () => {
    const response = await api.getDefaults();
    if(!response || !response.hasOwnProperty("clientId")) {
      showError(t("orders.errorLoadingClientConfig [missing clientId]"), "")
      return
    }
    SET_DEFAULTS(response);
  }

  const loadOrders = async () => {
    setIsLoading(true);
    const response = await api.getOrders();
    setIsLoading(false);
    if(!response || !response.map){
      showError("Error loading orders", JSON.stringify(response));
      return;
    }
    const castResponse = response.map( o => GiftCardsOrder.fromObject(o))
    setOrders(castResponse);
  }

  const loadOrder = async (id) => {
    setIsLoading(true);
    let response = await api.getOrder(id);
    setEditorMode(DEFAULT_MODE);
    setCurrentOrder(response);
    setIsLoading(false);
  }

  const closeHandler = (e) => {
    if(e.target.id==="orderFormBackdrop") setCurrentOrder(null)
  }

  const callbackHandler = (action) => {
    setEditorMode(DEFAULT_MODE);
    switch (action){
      case GiftCardsOrder.ACTION_ENUM.SAVE_ORDER:     saveOrder(); break;
      case GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS:   enableAssignMode(); break;
      case GiftCardsOrder.ACTION_ENUM.COMMIT_ORDER:   commitOrder(); break;
      case GiftCardsOrder.ACTION_ENUM.LOAD_CARDS:     loadCards(); break;
      case GiftCardsOrder.ACTION_ENUM.ACTIVATE_CARDS: activateCards(); break;
      case GiftCardsOrder.ACTION_ENUM.CANCEL_ORDER:   cancelOrder(); break;
      case undefined: break;
      default: throw new Error("Unrecognized action button name: " + action);
    }
  }

  const commitOrder = async () => {
    currentOrder.status = GiftCardsOrder.STATUS_ENUM.COMMITED;
    saveOrder();
  }

  const loadCards = async () => {
    if(currentOrder.id) {
      const res = await api.loadAllCards(currentOrder.id);
      if(!res || res.error) {
        showError(t('orders.errorLoadingCards'), JSON.stringify(res))
      } else {
        setCurrentOrder(res)
        await loadOrders();
      }
    }
  }

  const activateCards = async () => {
    if(currentOrder.id) {
      const updated = await api.activateAllCards(currentOrder.id);
      setCurrentOrder(updated)
      await loadOrders();
    }
  }

  const cancelOrder = async () => {
    if(currentOrder.id) {
      const updated = await api.cancelOrder(currentOrder.id);
      setCurrentOrder(updated)
      await loadOrders();
    }
  }

  const saveOrder = async () => {
    setEditorMode(DEFAULT_MODE);
    if( currentOrder.hasAllCardsAssigned ) {
      if(currentOrder.status === GiftCardsOrder.STATUS_ENUM.OPEN) {
        currentOrder.status = GiftCardsOrder.STATUS_ENUM.CARDS_ASSIGNED;
      }
    } else {
      if(currentOrder.status === GiftCardsOrder.STATUS_ENUM.CARDS_ASSIGNED) {
        currentOrder.status = GiftCardsOrder.STATUS_ENUM.OPEN
      }
    }
    if(currentOrder.id) {
      await api.updateOrder(currentOrder);
    } else {
      const saved = await api.placeOrder(currentOrder);
      currentOrder.id = saved.id
      currentOrder.reference = saved.reference
    }
    loadOrders();
  }

  const enableAssignMode = () => {
    setEditorMode(GiftCardsOrder.ACTION_ENUM.ASSIGN_CARDS);
  }

  useEffect( () => {
    loadDefaults();
    loadOrders();
  }, [])

  return <div className={`corporateOrders ${isLoading ? "loading" : ""}`}>
    <h1>{t('orders.mainHeadline')}</h1>
    <nav>
      <input onChange={handleSearchPhraseChange} placeholder={t('orders.searchPhrase')}/>
      <button onClick={handleLoadOrders} aria-label={t('orders.loadOrders')}
              className={"aux"}>{t('orders.refresh')}</button>
      <button onClick={handleAddOrder} aria-label={t('orders.loadOrders')} className={"main"}>{t('orders.addOrder')}</button>
    </nav>

    <OrderList orders={orders} handleOrderClick={handleOrderClick} searchPhrase={searchPhrase}/>
    <Order order={currentOrder} callbackHandler={callbackHandler} editorMode={editorMode} closeHandler={closeHandler}/>
    <ErrorDialog msg={error.msg} techInfo={error.techInfo} clearCallback={clearError}/>
    <LoadingSpinner/>
    <div onClick={closeHandler} className={currentOrder ? "" : "hidden"} id="orderFormBackdrop"></div>
  </div>;
}

export default Admin;